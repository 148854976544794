* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Space Mono', monospace;
  overflow: hidden;
  background: black;
}

.top-info-container {
  z-index: 1;
  top: 20px;
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
}

.bottom-info-container {
  z-index: 1;
  bottom: 20px;
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
}

.title {
  font-size: 35px;
  color: #e6f1ff;
}

.title-desc {
  margin-top: 5px;
  color: #ccd6f6;
  padding: 0 5px;
}

#infected {
  color: goldenrod;
}

#deaths {
  color: #ff4848;
}

#recovered {
  color: #1ae021;
}

.card {
  font-family: 'Space Mono', monospace;
  transition: 0.3s;
  background-color: black;
  width: 190px;
  border: 2px solid steelblue;
  border-radius: 10px;
  z-index: 2;
}

.container {
  /* padding: 3px 16px; */
  padding-top: 1px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: left;
}

.card-img {
  object-fit: contain;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-title {
  font-weight: bold;
}

.card-spacer {
  height: 5px;
}

.bottom-info {
  display: flex;
  flex-direction: column;
  font-size: 11px;
}

.scene-tooltip {
  z-index: 2;
}

.timeline-container {
  z-index: 1;
  position: absolute;
  display: flex;
  color: #fff;
  width: 60%;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .timeline-container {
    width: 90%;
  }
}

.slider {
  -webkit-appearance: none;
  height: 2px;
  width: 100%;
  margin-right: 10px;
  border-radius: 5px;
  background: #ccd6f6;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
  border: 2px solid #ccd6f6;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
  border: 2px solid #ccd6f6;
}

.play-button {
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: rgba(1, 1, 1, 0);
  border: 1px solid #ccd6f6;
  color: #ccd6f6;
  outline: none;
  width: 5em;
}

.trigger {
  background-color: rgba(1, 1, 1, 0);
  border: none;
  border-radius: 5px;
  color: #ccd6f6;
  padding: 12px 16px
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  transform: scale(1.1);
  transition: visibility 0s linear .25s, opacity .25s 0s, transform .25s
}

.modal-content {
  display: inherit;
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #003;
  padding: 1rem 1.5rem;
  width: 200px;
  border-radius: 25px;
  overflow: auto
}

.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: .25rem;
  background-color: #d3d3d3
}

.close-button:hover {
  background-color: #a9a9a9
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity .25s 0s, transform .25s
}

.dropbtn {
  background-color: #f1f1f1;
  color: #000;
  padding: 16px;
  font-size: 16px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 15px
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 4px;
  left: auto;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
  z-index: 1;
  border-radius: 15px
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 20px
}

.dropdown a:hover {
  background-color: #ddd
}

.show {
  display: block
}
